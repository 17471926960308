<template>
  <div
    :id='id' class='c-modal modal' tabindex='0'
    style='z-index: 1003; display: none; opacity: 0; top: 4%; transform: scaleX(0.8) scaleY(0.8)'
  >
    <div class='type-voucher-container'>
      <div class='text-2xl-bold'>Tipo de comprobante</div>
      <div style='display: flex; flex-direction: column; gap: 16px;'>
        <div style='display: flex; gap: 12px; margin-left: -12px;'>
          <CustomRadioButton
            radioId='1'
            inputValue='Boleta'
            v-model='checkedTypeVoucher'
            label='Boleta'
          />
          <CustomRadioButton
            radioId='2'
            inputValue='Factura'
            v-model='checkedTypeVoucher'
            label='Factura'
          />
        </div>
        <InputText
          v-show="checkedTypeVoucher === 'Boleta'"
          placeholder='Nombre(s) y Apellidos'
          v-model='name'
          missingErrorMessage='Ingresa tus nombre(s) y Apellidos.'
          :showEmptyInputError='tryToSubmit && !name'
        />
        <InputText
          v-show="checkedTypeVoucher === 'Factura'"
          placeholder='Razón Social'
          v-model='razonSocial'
          missingErrorMessage='Ingresa tus nombre(s) y Apellidos.'
          :showEmptyInputError='tryToSubmit && !razonSocial'
        />
        <InputText
          v-show="checkedTypeVoucher === 'Boleta'"
          placeholder='DNI'
          v-model='documentNumber'
          missingErrorMessage='Ingresa tu DNI.'
          :showEmptyInputError='tryToSubmit && !documentNumber'
        />
        <InputText
          v-show="checkedTypeVoucher === 'Factura'"
          placeholder='RUC'
          v-model='ruc'
          missingErrorMessage='Ingresa tu RUC.'
          :showEmptyInputError='tryToSubmit && !ruc'
        />
        <InputText
          placeholder='Celular'
          v-model='phone'
          missingErrorMessage='Ingresa tu Celular.'
          :showEmptyInputError='tryToSubmit && !phone'
        />
        <InputText
          placeholder='Correo electrónico'
          v-model='email'
          missingErrorMessage='Ingresa tu Correo electrónico.'
          :showEmptyInputError='tryToSubmit && !email'
        />
      </div>
      <div class=''>
        <ButtonDefinitive
          label="Guardar"
          @click="sendVoucherData"
        />
        <ButtonDefinitive
          variant='tertiary'
          label="Cancelar"
          @click='handleCloseModal'
        />
      </div>
    </div>

  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import CustomRadioButton from '@/components/Form/CustomRadioButton.vue'
import InputText from '@/components/Form/InputText.vue'

export default {
  name: "ModalTypeVoucher",
  components: { ButtonDefinitive, CustomRadioButton, InputText },
  props: {
    id: String,
  },
  data() {
    return {
      checkedTypeVoucher: "Boleta",
      ruc: "",
      razonSocial: "",
      name: "",
      documentNumber: "",
      phone: "",
      email: "",
      favorite: false,

      tryToSubmit: false
    };
  },
  watch: {
    checkedTypeVoucher() {
      // this.name = "";
      // this.documentNumber = "";
    },
  },
  computed: {
    user() {
      return this.$store.getters.USER;
    },
  },
  async mounted() {
    this.checkedTypeVoucher = this.user.ruc ? "Factura" : "Boleta";
    this.ruc = this.user.ruc;
    this.razonSocial = this.user.razonSocial;
    this.name = this.user.fullName;
    this.documentNumber = this.user.dni;
    this.phone = this.user.celular;
    this.email = this.user.email;
  },
  methods: {
    validData() {
      if (
        !this.name ||
        (this.checkedTypeVoucher === "Boleta" && !this.documentNumber) ||
        (this.checkedTypeVoucher === "Factura" && !this.ruc) ||
        (this.checkedTypeVoucher === "Boleta" && !this.name) ||
        (this.checkedTypeVoucher === "Factura" && !this.razonSocial) ||
        !this.phone ||
        !this.email
      ) {
        this.showError("Por favor, rellena la información correctamente.");
        return false;
      }

      if (this.phone.length !== 9) {
        this.showError("El campo número de telefono debe poseer 9 digitos.");
        return false;
      }

      if (!this.validateEmail(this.email)) {
        this.showError("Por favor, ingrese un email válido.");
        return false;
      }

      if (
        this.checkedTypeVoucher === "Boleta" &&
        this.documentNumber.length !== 8
      ) {
        this.showError("DNI debe tener 8 caracteres.");
        return false;
      }

      if (
        this.checkedTypeVoucher === "Factura" &&
        this.ruc.length !== 11
      ) {
        this.showError("RUC debe tener 11 caracteres.");
        return false;
      }

      return true;
    },
    sendVoucherData() {
      const isValid = this.validData();

      if (isValid === true) {
        const voucherData = {
          typeVoucher: this.checkedTypeVoucher,
          name: this.checkedTypeVoucher === "Boleta"? this.name : this.razonSocial,
          documentNumber:this.checkedTypeVoucher === "Boleta"? this.documentNumber : this.ruc ,
          phone: this.phone,
          email: this.email,
        };
        this.$emit("getVoucherData", voucherData);
        this.closeModal(this.id);
      }
    },
    handleCloseModal() {
      this.closeModal(this.id);
    }
  },
};
</script>

<style lang='scss'>
.type-voucher-container {
  display: flex; flex-direction: column; gap: 32px;
  padding: 32px 20px;
}
</style>
